import $api from "@/api";
import { defineStore, storeToRefs } from "pinia";
import UserAvailable from "./enums/userAvailable.enum";
import { useInboxStore } from "./inbox";
import { useRootStore } from "./root";
import { useTagStore } from "./tag";
import type {
  AutocompleteContactItem,
  AutocompleteContactItemSms,
  SearchSuggestionsItems,
  State,
  TeamMate,
} from "./types/user";
import dayjs from "dayjs";
import type { MovementFor, Views } from "./types/inbox";
import $apiv3 from "@/apiv3";
import type { AxiosResponse } from "axios";

export const useUserStore = defineStore({
  id: "user",
  state: (): State => ({
    user: null,
    teamMates: [],
    userSignaturesMap: {},
    userSignaturesDetailMap: {},
    userSettings: null,
    editorUiStatus: 0,
    editorSettings: {
      imageDefaultWidth: 300,
      sendShortcutDisabled: false,
      wordPasteModal: true,
    },
    userOnlineStatus: UserAvailable.unavailable,
    viewingUser: undefined,
  }),
  actions: {
    async fetchSocketToken(): Promise<{ token: string }> {
      const res = await $api.get("/get_socket_token.php");
      return res.data.data;
    },
    async fetchUserInfo(): Promise<void> {
      const rootStore = useRootStore();
      const inboxStore = useInboxStore();
      const tagStore = useTagStore();

      const res = await $api.get("/pingv2.php", {
        params: {
          mailboxID: "me",
        },
      });

      const { data } = res.data;

      this.user = data.userInfo;

      if (this.user) {
        this.viewingUser = {
          avatarTag: this.user.avatarTag,
          avatarUrl: this.user.avatarUrl,
          email: this.user.email,
          firstname: this.user.firstname,
          id: this.user.id,
          lastname: this.user.lastname,
          status: "Online",
          time: dayjs().utc().valueOf(),
        };
      }

      // Creating a map for fromAddresses and storing in inboxStore
      const fromAddresses: {
        email: string;
        isDefault: boolean;
        mailboxId: number;
        name: string;
        inboxType: number;
      }[] = data.fromAddresses;

      fromAddresses.forEach((a) => {
        if (!inboxStore.inboxesFromAddressesMap[a.mailboxId]) {
          inboxStore.inboxesFromAddressesMap[a.mailboxId] = [];
        }

        inboxStore.inboxesFromAddressesMap[a.mailboxId]?.push({
          email: a.email,
          isDefault: a.isDefault,
          name: a.name,
          inboxType: a.inboxType,
        });
      });

      // Set user pinned universal inboxes.
      const pins = data.pins;
      rootStore.pinnedUniversalInboxes = pins.inboxes;
      rootStore.pinnedUniversalTags = pins.tags;

      // Set user settings
      this.userSettings = data.userSettings;
      const resUserSettings = await $api.get("/get-user-settings.php");
      const userSettingsData = resUserSettings.data;
      if (userSettingsData.status == "success") {
        const userFontSettings = userSettingsData.data.settings.font;
        this.userSettings!.font = {
          color: userFontSettings.color,
          backgroundColor: userFontSettings.backgroundColor,
          family: userFontSettings.family,
          size: userFontSettings.size,
        };
      }

      // Setting current user availability
      const teamMates = data.teammates as TeamMate[];
      const foundUser = teamMates.find((t) => t.id === this.user?.id);

      const inboxes = data.inboxes as {
        [key: string]: any;
        id: string | number;
        mine: MovementFor;
        allUnassignedAssigned: MovementFor;
      }[];

      this.userOnlineStatus =
        foundUser?.isAvailable ?? UserAvailable.unavailable;

      // Setting default dayjs configs
      const userTz = this.userSettings?.tz;
      // if not provided dayjs takes local timezone
      if (userTz) {
        dayjs.tz.setDefault(userTz);
      }

      // Setting user inbox access
      const accessStatus = data.uiStatus;
      this.editorUiStatus = data.uiStatus.editor;
      this.editorSettings = data.editorSettings;

      inboxStore.inboxAccessMap = {
        chat: accessStatus.chat === "0" ? false : true,
        mail: accessStatus.mail === "0" ? false : true,
        sms: accessStatus.sms === "0" ? false : true,
        whatsapp: accessStatus.whatsapp === "0" ? false : true,
        discussion: false,
      };

      inboxes.forEach((inbox) => {
        inboxStore.inboxThreadMovementSettings[inbox.id] = {
          allUnassignedAssigned: inbox.allUnassignedAssigned,
          mine: inbox.mine,
        };
      });

      const views = data.views as Views[];

      views.forEach((view) => {
        const inboxId: string = view.mailboxId.toString();
        if (!inboxStore.inboxViewsMap[inboxId]) {
          inboxStore.inboxViewsMap[inboxId] = [];
        }
        inboxStore.inboxViewsMap[inboxId]?.push(view);
      });
    },
    async fetchTeamMates(inboxId: string): Promise<void> {
      let res: AxiosResponse<any, any>;

      if (inboxId === "me") {
        const rootStore = useRootStore();
        const { pinnedUniversalInboxes } = storeToRefs(rootStore);
        res = await $apiv3.get("/members", {
          params: {
            inboxIds: pinnedUniversalInboxes.value,
          },
        });
      } else {
        const url = `inboxes/${inboxId}/members`;
        res = await $apiv3.get(url);
      }

      this.teamMates = res.data;
    },
    async fetchContactsBySearchQuery(
      searchStr: string
    ): Promise<AutocompleteContactItem[]> {
      const res = await $api.get("/contacts/autocomplete.php", {
        params: {
          q: searchStr,
        },
      });

      const { data, status } = res.data;

      if (status === "error") {
        throw new Error();
      }

      const emails: AutocompleteContactItem[] = data.contacts.map(
        (c: Record<string, unknown>) => ({
          id: c.id,
          name: c.name,
          email: c.email,
          avatarTag: c.avatarTag,
        })
      );

      return emails;
    },
    async fetchSearchSuggestions(
      searchStr: string
    ): Promise<SearchSuggestionsItems[]> {
      if (searchStr.length > 3) {
        const inboxStore = useInboxStore();
        let inboxIds: string[];
        if (inboxStore.isInboxUniversal) {
          inboxIds = Object.keys(inboxStore.inboxesMap);
        } else {
          inboxIds = [inboxStore.inbox?.id?.toString() ?? ""];
        }
        const res = await $apiv3.get("/conversations/searchSuggestions", {
          params: {
            search: searchStr,
            inboxIds: inboxIds,
          },
        });

        const { conversations } = res.data;

        const searchSuggestions: SearchSuggestionsItems[] = conversations.map(
          (c: Record<string, unknown>) => ({
            id: c.id,
            subject: c.subject,
            snippet: c.snippet,
            inboxId: c.inboxId,
          })
        );

        return searchSuggestions;
      }
      return [];
    },
    async fetchContactsBySearchQuerySmS(
      searchStr: string
    ): Promise<AutocompleteContactItemSms[]> {
      const res = await $api.get("/contacts/autocomplete-sms.php", {
        params: {
          q: searchStr,
        },
      });

      const { data, status } = res.data;

      if (status === "error") {
        throw new Error();
      }

      const phones: AutocompleteContactItemSms[] = data.contacts.map(
        (c: Record<string, any>) => ({
          id: c.id,
          name: c.name,
          email: c.email,
          phone: c.phones[0]?.phoneTxt,
          avatarTag: c.avatarTag,
        })
      );

      return phones;
    },
    async fetchUserSignature(inboxId: string): Promise<void> {
      const res = await $api.get("/signatures/list.php", {
        params: {
          mailboxId: inboxId,
        },
      });

      const { data, status } = res.data;

      if (status === "error") {
        throw new Error();
      }

      this.userSignaturesMap[inboxId] = data;
    },
    async fetchUserSignatureById(id: string | number): Promise<void> {
      const res = await $api.get("/signatures/get.php", {
        params: {
          id,
        },
      });

      const { data, status } = res.data;

      if (status === "error") {
        throw new Error();
      }

      this.userSignaturesDetailMap[id] = data.signature;
    },
    async saveFontChange(
      reset: boolean,
      size: string,
      family: string,
      color: string,
      backgroundColor: string
    ): Promise<void> {
      const res = await $api.post("/saveFont.php", {
        size,
        family,
        color,
        backgroundColor,
        reset,
      });

      const { status } = res.data;

      if (status === "error") {
        throw new Error();
      } else {
        if (reset) {
          size = "0";
          family = "";
          color = "";
          backgroundColor = "";
        }
        this.userSettings!.font = { size, family, color, backgroundColor };
      }
    },
    async updateUserAvailability(isAvailable: UserAvailable) {
      const formData = new FormData();
      formData.append("isAvailable", isAvailable);

      const res = await $api.post("/updateUserAvailability", formData);

      const { status } = res.data;

      if (status === "error") {
        throw new Error("Couldn't update the online status, please try again");
      }

      this.userOnlineStatus = isAvailable;
    },
  },
});
