import $api from "@/api";
import $apiv3 from "@/apiv3";
import { defineStore } from "pinia";
import type {
  Integration,
  State,
  ContactIntegration,
  AllContacts,
  IntegrationVariableRequest,
  IntegrationRenderData,
  IntegrationVariableInfo,
  IntegrationFetchData,
} from "./types/integration";
import type { InboxType } from "./types/inbox";
import type { ComponentPublicInstance, defineAsyncComponent } from "vue";
import { capitalizeFirstLetter } from "@/utils/helperFunction";

export const useIntegrationStore = defineStore({
  id: "integration",
  state: (): State => ({
    integrationsMap: {},
    fetchedContactCustomFields: false,
    contactCustomFields: [],
    fetchedInboxContactCustomFields: false,
    inboxContactCustomFields: {},
    contactIntegrationDataMap: {},
    allContactsInformation: null,
    linkedContactsInformation: [],
    contactGroups: null,
    modal: null,
    isDeeplConnected: null,
    deeplLanguages: [],
    linkedContactIds: null,
    displayedContactId: "",
    reloadContact: false,
    variables: {},
    contactId: 0,
    conversationId: 0,
    email: "",
    phone: "",
    inboxType: "mail",
    inboxId: 0,
    forceFetchIntegrationVariables: true,
  }),
  actions: {
    /**
     * Mounts a modal to modal target.
     */
    setModal<T extends ComponentPublicInstance>(
      payload: {
        component: ReturnType<typeof defineAsyncComponent>;
        props: T["$props"];
      } | null
    ) {
      this.modal = payload;
    },
    /**
     * Fetches integration, if integration cache present return cache.
     */
    async fetchIntegrations(
      inboxId: number,
      inboxType: string,
      inboxSubType: string
    ): Promise<Integration[]> {
      const integrations = this.integrationsMap[inboxId];

      if (integrations) return integrations;

      const res = await $api.get("/connected_integrations.php", {
        params: {
          mailbox_id: inboxId,
          inbox_subtype: inboxSubType,
          inbox_type: inboxType,
        },
      });

      const { status, message, data } = res.data;

      if (status === "error") {
        throw new Error(message);
      }

      this.integrationsMap[inboxId] = data;

      return data;
    },
    async checkDeepLConnection(): Promise<void> {
      const res = await $api.get("/integration/deepL/checkStatus.php");

      const { status, message, data } = res.data;

      if (status === "error") {
        throw new Error(message);
      }

      this.isDeeplConnected = data.connected === 1;
    },
    async fetchDeeplLanguages(): Promise<void> {
      const res = await $api.get("/integration/deepL/languages.php");

      const { status, message, data } = res.data;

      if (status === "error") {
        throw new Error(message);
      }

      this.deeplLanguages = data;
    },
    async translateText(
      text: string,
      language: string,
      action: string
    ): Promise<string> {
      const res = await $api.post("/integration/deepL/translate", {
        text,
        language,
        action,
      });

      const { status, message, data } = res.data;

      if (status === "error") {
        throw new Error(message);
      }
      return data;
    },
    async fetchAccountContactCustomFields(inboxId: string) {
      if (!this.fetchedContactCustomFields) {
        const res = await $apiv3.get(`/customFields`);

        const { status, message, data } = res.data;

        if (status === "error") {
          throw new Error(message);
        }

        const customFields: {
          id: number;
          name: string;
          description: string;
          type: "0";
          date: string;
          checked: boolean;
        }[] = data;
        this.contactCustomFields = [...customFields];
        this.fetchedContactCustomFields = true;

        const inboxCustomFieldIdMap: { [customFieldId: string]: boolean } = {};
        this.inboxContactCustomFields[inboxId].forEach((customField) => {
          inboxCustomFieldIdMap[customField.id] = true;
        });

        this.contactCustomFields.forEach((customField) => {
          customField.checked = inboxCustomFieldIdMap[customField.id];
        });
      }
    },
    async fetchInboxContactCustomFields(inboxId: string) {
      if (!this.fetchedInboxContactCustomFields) {
        const res = await $apiv3.get(`/customFields/inbox/${inboxId}`);

        const { status, message, data } = res.data;

        if (status === "error") {
          throw new Error(message);
        }

        const customFields: {
          id: number;
          name: string;
          description: string;
          type: "0";
          date: string;
          checked: false;
        }[] = data;
        this.inboxContactCustomFields[inboxId] = [...customFields];
        this.fetchedInboxContactCustomFields = true;
      }
    },
    async handleContactCustomFieldsInboxSettings(
      inboxId: string,
      hideIds: number[],
      unhideIds: number[]
    ) {
      const res = await $apiv3.put(`customFields/inbox/${inboxId}`, {
        hideIds: hideIds,
        unhideIds: unhideIds,
      });

      const { status, message, data } = res.data;

      if (status === "error") {
        throw new Error(message);
      }

      this.inboxContactCustomFields[inboxId] = data;
    },
    async fetchContactDetails(
      id: string,
      inboxId: string,
      threadId: string,
      inboxType: InboxType
    ) {
      const res = await $api.post(`/contacts/getSidebarContactDetails.php`, {
        contactID: id,
        mailboxID: inboxId,
        mailboxType: inboxType,
        threadID: threadId,
      });

      const { status, message, data } = res.data;

      if (status === "error") {
        throw new Error(message);
      }

      this.contactIntegrationDataMap[threadId] = data;
    },

    async fetchContactGroups() {
      const res = await $api.get(`contacts/getGroups.php`);

      const { status, message, data } = res.data;

      if (status === "error") {
        throw new Error(message);
      }

      this.contactGroups = data;
    },

    async fetchContactData(contactId: number): Promise<ContactIntegration> {
      const resLinked = await $api.get(`contacts/get-v3.php`, {
        params: {
          id: contactId,
        },
      });

      const { status, message, data } = resLinked.data;
      if (status === "error") {
        throw new Error(message);
      }
      data.notes = [];
      data.type = "";
      data.firstSeen = "";
      data.lastSeen = "";
      return data;
    },
    handleLinkedContactIds(contacts: number[] | string[]) {
      this.linkedContactIds = [];
      if (contacts.length > 0) {
        contacts.forEach((e) => {
          this.linkedContactIds!.push(Number(e));
        });
      }
    },
    async fetchAllContacts(
      page: string,
      threadId: string,
      contactIds: number[],
      threadLoading: boolean
    ) {
      const params: Record<string, any> = {
        page: page,
        threadId: threadId,
      };
      const res = await $api.get(`contacts/list-v2.php`, {
        params,
      });

      const { status, message, data } = res.data;

      if (status === "error") {
        throw new Error(message);
      }
      this.allContactsInformation = data;
      if (
        threadLoading ||
        !this.linkedContactsInformation ||
        this.linkedContactsInformation.length == 0
      ) {
        this.handleLinkedContacts(contactIds);
      } else {
        const prevLinkedContacts: ContactIntegration[] = [
          ...this.linkedContactsInformation,
        ];
        this.linkedContactsInformation = prevLinkedContacts;
      }
    },

    async handleLinkedContacts(contactIds: number[]) {
      const contactPromises: Promise<ContactIntegration>[] = [];
      for (const contactId of contactIds) {
        contactPromises.push(this.fetchContactData(contactId));
      }
      const linkedContacts: ContactIntegration[] = await Promise.all(
        contactPromises
      );
      if (linkedContacts.length > 0) {
        this.linkedContactsInformation = [...linkedContacts];
      }
    },

    async handleContactLinkSearch(contacts: AllContacts, contactIds: number[]) {
      const allContacts: ContactIntegration[] = [];
      const linkedContacts: ContactIntegration[] = [];
      contacts.contacts.forEach((e) => {
        if (contactIds.includes(e.id)) {
          linkedContacts.push(e);
        } else {
          allContacts.push(e);
        }
      });
      this.allContactsInformation = {
        contacts: allContacts,
        nextPage: contacts.nextPage,
      };
      this.linkedContactsInformation = linkedContacts;
    },

    async linkContactToThread(
      contactId: string,
      threadId: string,
      inboxId: string
    ) {
      const contactPromise: Promise<ContactIntegration>[] = [
        this.fetchContactData(Number(contactId)),
      ];
      let linkedContacts: ContactIntegration[] = await Promise.all(
        contactPromise
      );
      const prevLinkedContacts = this.linkedContactsInformation;
      if (prevLinkedContacts && prevLinkedContacts.length > 0) {
        linkedContacts = linkedContacts.concat(prevLinkedContacts);
      }
      this.linkedContactsInformation = linkedContacts;
      const prevAllContacts = this.allContactsInformation!.contacts;
      const nextPage: boolean = this.allContactsInformation!.nextPage;
      for (let i = 0; i < prevAllContacts.length; i++) {
        if (prevAllContacts[i].id == Number(contactId)) {
          prevAllContacts.splice(i, 1);
          break;
        }
      }
      this.allContactsInformation = {
        contacts: prevAllContacts,
        nextPage: nextPage,
      };

      const prevLinkedContactIds = this.linkedContactIds
        ? [...this.linkedContactIds]
        : [];

      prevLinkedContactIds.push(Number(contactId));
      this.linkedContactIds = prevLinkedContactIds;

      const res = await $api.post(`contacts/link.php`, {
        contactId: contactId,
        threadId: threadId,
        mailboxId: inboxId,
      });

      if (this.displayedContactId.length == 0) {
        this.displayedContactId = contactId;
      }

      const { status, message } = res.data;

      if (status === "error") {
        throw new Error(message);
      }
    },

    async unlinkContactFromThread(contactId: string, threadId: string) {
      const prevLinkedContacts: ContactIntegration[] = [
        ...this.linkedContactsInformation!,
      ];
      const prevAllContacts = this.allContactsInformation!.contacts;
      const nextPage = this.allContactsInformation!.nextPage;
      for (let i = 0; i < prevLinkedContacts.length; i++) {
        if (prevLinkedContacts[i].id == Number(contactId)) {
          prevAllContacts.push(prevLinkedContacts[i]);
          prevLinkedContacts.splice(i, 1);
          break;
        }
      }
      this.linkedContactsInformation = prevLinkedContacts;
      this.allContactsInformation = {
        contacts: prevAllContacts,
        nextPage: nextPage,
      };

      const prevLinkedContactIds: number[] = [...this.linkedContactIds!];
      for (let i = 0; i < prevLinkedContactIds.length; i++) {
        if (prevLinkedContactIds[i] === Number(contactId)) {
          prevLinkedContactIds.splice(i, 1);
          break;
        }
      }
      this.linkedContactIds = prevLinkedContactIds;
      if (contactId == this.displayedContactId) {
        this.displayedContactId = this.linkedContactIds[0].toString();
        this.reloadContact = true;
      }

      const res = await $api.post(`contacts/unlink.php`, {
        contactId: contactId,
        threadId: threadId,
      });

      const { status, message } = res.data;

      if (status === "error") {
        throw new Error(message);
      }
    },

    async updateContactDetail(
      id: number,
      contactDetail: {
        firstName: string;
        middleName: string;
        lastName: string;
        jobTitle: string;
        company: string;
        emails: string[];
        phones: {
          phoneTxt: string;
          type: string;
        }[];
        customFields: {
          fieldId: number;
          value: string;
        }[];
      }
    ) {
      const res = await $api.post(`/contacts/update.php`, {
        id,
        firstname: contactDetail.firstName,
        middlename: contactDetail.middleName,
        lastname: contactDetail.lastName,
        jobTitle: contactDetail.jobTitle,
        company: contactDetail.company,
        emails: contactDetail.emails,
        phones: contactDetail.phones,
        customFields: contactDetail.customFields,
      });

      const { status, message } = res.data;

      if (status === "error") {
        throw new Error(message);
      }
    },
    async createContactDetail(
      contactDetail: {
        firstName: string;
        middleName: string;
        lastName: string;
        jobTitle: string;
        company: string;
        emails: string[];
        phones: {
          phoneTxt: string;
          type: string;
        }[];
        customFields: {
          id: number;
          value: string;
        }[];
      },
      threadId: string
    ): Promise<string> {
      const res = await $apiv3.post(`/contacts`, {
        firstname: contactDetail.firstName,
        middlename: contactDetail.middleName,
        lastname: contactDetail.lastName,
        jobTitle: contactDetail.jobTitle,
        company: contactDetail.company,
        emails: contactDetail.emails,
        phones: contactDetail.phones,
        customFields: contactDetail.customFields,
        threadId: threadId,
      });

      const { status, statusText, data } = res;

      if (status !== 200) {
        throw new Error(statusText);
      }
      return data.id.toString();
    },

    async addContactGp(
      contactId: number,
      threadId: string,
      gpId: number,
      name: string
    ) {
      const res = await $api.post(`/contacts/addGroup.php`, {
        contactId,
        groupId: gpId,
        name,
      });

      const { status, message, data } = res.data;

      if (status === "error") {
        throw new Error(message);
      }

      const addedGp: { id: number; name: string } = data;
      const idx = this.contactIntegrationDataMap[threadId]?.contact.groups
        ?.map((val) => val.group_id)
        .indexOf(gpId);
      if (idx == -1) {
        this.contactIntegrationDataMap[threadId]?.contact.groups?.push({
          name: addedGp.name,
          group_id: gpId,
        });
      } else {
        if (typeof idx !== "undefined") {
          this.contactIntegrationDataMap[threadId]?.contact.groups?.splice(
            idx,
            1
          );
        }
      }
    },

    async addContactNote(contactId: string, content: string) {
      const res = await $api.post(`/contacts/addNote`, {
        contact_id: contactId,
        body: content,
      });

      const { status, message } = res.data;

      if (status === "error") {
        throw new Error(message);
      }
    },

    async addContactActivity(
      contactId: string,
      threadId: string,
      activity: {
        title: string;
        startDate: string;
        endDate: string;
        body: string;
        type: "Meeting" | "Call";
      }
    ) {
      const res = await $api.post(`/contacts/addActivity`, {
        title: activity.title,
        start_date: activity.startDate,
        end_date: activity.endDate,
        body: activity.body,
        contact_id: parseInt(contactId),
        type: activity.type,
      });

      const { status, message, data } = res.data;

      if (status === "error") {
        throw new Error(message);
      }

      const activityId = data.activity?.id;

      this.contactIntegrationDataMap[threadId]?.contact.activities?.push({
        body: {
          body: activity.body,
          start_date: activity.startDate,
          direction: "",
          end_date: activity.endDate,
          title: activity.title,
          type: activity.type,
        },
        createdAt: "",
        data_type: "activities",
        id: activityId,
        status: "upcoming",
        type: "custom",
      });
    },
    async fetchIntegrationVariables(): Promise<{
      [inboxId: string]: IntegrationVariableInfo[];
    }> {
      if (this.forceFetchIntegrationVariables) {
        const { inboxId, conversationId, email, phone, inboxType, contactId } =
          this;

        if (conversationId > 0 && contactId > 0) {
          const integrationVariablePromises: Promise<IntegrationRenderData>[] =
            [];
          for (const integration of this.integrationsMap[inboxId]) {
            if (
              integration.type == "crm" &&
              integration.lname != "salesforce"
            ) {
              integrationVariablePromises.push(
                _getSingleIntegrationData({
                  inboxId,
                  conversationId,
                  email,
                  phone,
                  inboxType,
                  contactId,
                  integration,
                })
              );
            }
          }
          const integrationFetchDataList: IntegrationRenderData[] =
            await Promise.all(integrationVariablePromises);
          for (const integrationFetchData of integrationFetchDataList) {
            if (integrationFetchData?.fetch) {
              const variables = parseIntegrationComponents(
                integrationFetchData.fetch,
                integrationFetchData.integration
              );
              this.variables[integrationFetchData.integration.lname] =
                variables;
            }
          }
          this.forceFetchIntegrationVariables = false;
        }
      }
      return this.variables;
    },
  },
});

async function _getSingleIntegrationData(
  request: IntegrationVariableRequest
): Promise<IntegrationRenderData> {
  const {
    inboxId,
    conversationId,
    email,
    phone,
    inboxType,
    contactId,
    integration,
  } = request;
  const res = await $api.get(
    `/integration-vue/${integration.lname}/${integration.lname}.php`,
    {
      params: {
        mailbox_id: inboxId,
        thread_id: conversationId,
        email: email,
        phone: phone,
        inbox_type: inboxType,
        integration_id: integration.id,
        contactID: contactId,
      },
    }
  );
  const { data } = res.data;
  data.integration = integration;
  return data;
}

function parseIntegrationComponents(
  fetchData: IntegrationFetchData[],
  integration: Integration
) {
  const variablesMap: { [key: string]: IntegrationVariableInfo } = {};
  for (const item of fetchData) {
    for (const key of Object.keys(item.components)) {
      const displayItem = item.components[key];
      for (const component of displayItem) {
        if (!component.id) {
          const key = `{{${integration.lname}.${item.class}.${component.class}}}`;
          variablesMap[key] = {
            display: `${integration.name} ${capitalizeFirstLetter(
              item.class
            )} ${component.label}`,
            key,
            type: "integration",
            value: component.value,
          };
          if (item.component_type == "multiple") {
            for (let i = 1; i <= 5; i++) {
              const key = `{{${integration.lname}.${item.class}.${i}.${component.class}}}`;
              variablesMap[key] = {
                display: `${integration.name} ${capitalizeFirstLetter(
                  item.class
                )} ${i} ${component.label}`,
                key,
                type: "integration",
                value: component.value,
              };
            }
          }
        }
      }
    }
  }
  return Object.values(variablesMap);
}
