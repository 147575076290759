import $api from "@/api";
import { defineStore, storeToRefs } from "pinia";
import { POSITION, useToast } from "vue-toastification";
import type { EmailDraft, ReplierAttachment, State } from "./types/email";
import UndoToast from "@/components/partials/UndoToast.vue";
import { useThreadStore } from "./thread";
import type { ThreadDraft, ThreadEmailData } from "./types/thread";
import { useUserStore } from "./user";
import { sanitizeEmail } from "@/utils/helperFunction";
import $apiv3 from "@/apiv3";
import useAxiosController from "@/functions/useAxiosController";

const { createReqController, cancelReq } = useAxiosController();

export const useEmailStore = defineStore({
  id: "email",
  state: (): State => ({
    emailDraftsMap: {},
    emailContentMap: {},
  }),
  actions: {
    async fetchEmailContent(
      id: string,
      inboxId: string,
      type: "text" | "html",
      isDraft?: boolean
    ): Promise<string> {
      const res = await $apiv3.get(
        `/inboxes/${inboxId}/emails/${id}/content/${type}`
      );
      let content = "";
      if (res.data) {
        content = res.data;
        if (type === "text") {
          content = content.replace(
            /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=;]*)/g,
            `<a href="$&" target="_blank">$&</a>`
          );
        }

        if (!isDraft) {
          this.emailContentMap[id] = {
            html: type === "html" ? content : null,
            text: type === "text" ? content : null,
          };
        }
      }
      return content;
    },
    async fetchDraft(id: string, inboxId: string): Promise<EmailDraft> {
      const threadDraft: ThreadDraft = (await this.fetchEmail(
        id,
        inboxId
      )) as ThreadDraft;
      const html = await this.fetchEmailContent(id, inboxId, "html", true);
      const emailDraft: EmailDraft = {
        bcc: threadDraft.bcc,
        cc: threadDraft.cc,
        from: threadDraft.from,
        html: html,
        subject: threadDraft.subject,
        text: "",
        to: threadDraft.to,
        mailboxID: threadDraft.inboxId,
        replyAll: threadDraft.replyAll,
        threadID: threadDraft.threadId,
        draftID: threadDraft.id,
        owner: threadDraft.owner,
        shared: threadDraft.shared,
        consecutiveCall: true,
        forwardOf: threadDraft.forwardOf
          ? threadDraft.forwardOf.toString()
          : undefined,
        inReplyTo: threadDraft.inReplyTo,
        files: threadDraft.attachments as ReplierAttachment[],
      };
      return emailDraft;
    },
    async fetchEmail(id: string, inboxId: string): Promise<ThreadEmailData> {
      const res = await $apiv3.get(`/inboxes/${inboxId}/emails/${id}`);
      return res.data;
    },
    async saveEmailDraft(
      inboxId: string,
      draftData: Omit<EmailDraft, "draftID" | "threadID"> & {
        draftID?: number;
        threadID?: number;
      }
    ): Promise<{ draftId: number; threadId: number }> {
      const saveDraftInput = {
        ...draftData,
        mailboxID: parseInt(inboxId),
      };
      saveDraftInput.files = draftData.files.map((file) => {
        if (typeof file != "number") {
          return file.id;
        } else {
          return file;
        }
      });
      const url = "/saveDraft.php";

      // First cancel the previous request if happening
      cancelReq(url);

      // create new request controller for axios
      const controller = createReqController(url);

      const res = await $api.post(url, saveDraftInput, {
        signal: controller.signal,
      });

      const { status, message, data } = res.data;

      if (status === "error") {
        throw new Error(message);
      }

      return {
        draftId: data.draftID,
        threadId: data.threadID,
      };
    },
    async shareEmailDraft(
      inboxId: string,
      draftId: number,
      threadId: string
    ): Promise<void> {
      const res = await $api.post("/shareDraft.php", {
        draftId,
        mailboxId: inboxId,
        threadId,
      });

      const { status } = res.data;

      if (status === "error") {
        throw new Error("Couldn't share the draft, please try again.");
      }
    },
    async deleteEmailDraft(
      id: number,
      threadId: string,
      inboxId: string
    ): Promise<void> {
      const res = await $api.post("/discardDraft.php", {
        draftId: id,
        mailboxId: inboxId,
        threadId: threadId,
      });

      const { status, message } = res.data;

      if (status === "error") {
        throw new Error(message);
      }

      // Remove the draft from draft map
      this.emailDraftsMap[threadId] = this.emailDraftsMap[threadId]?.filter(
        (d) => d.draftID != id
      );
    },
    async sendEmail(
      inboxId: string,
      draftData: EmailDraft,
      isComposer: boolean,
      closeThread?: boolean,
      hideUndoToast?: boolean
    ): Promise<void> {
      const userStore = useUserStore();
      const threadStore = useThreadStore();
      const toast = useToast();

      const { userSettings } = storeToRefs(userStore);
      const { threads } = storeToRefs(threadStore);

      draftData.html = sanitizeEmail(draftData.html);

      const sendMailInput = {
        ...draftData,
        mailboxID: parseInt(inboxId),
        archive: closeThread,
      };

      sendMailInput.files = draftData.files.map((file) => {
        if (typeof file != "number") {
          return file.id;
        } else {
          return file;
        }
      });

      // update msgCount of threads
      const foundThread = threads.value.find(
        (t) => t.id === draftData.threadID
      );

      if (foundThread) {
        foundThread.msgCount += 1;
      }

      // cancel save draft request
      cancelReq("/saveDraft.php");

      const res = await $api.post("/sendMail.php", sendMailInput);

      const { status, message } = res.data;

      if (status === "error") {
        throw new Error(message);
      }

      if (hideUndoToast) return;

      // Rendering undo toast
      toast.clear();
      toast.success(
        {
          component: UndoToast,
          props: {
            inboxId,
            draftData,
            isComposer,
          },
        },
        {
          position: POSITION.BOTTOM_LEFT,
          timeout: (userSettings.value?.undoTimer ?? 10) * 1000,
          pauseOnHover: false,
          pauseOnFocusLoss: false,
        }
      );
    },
    async undoSend(
      draftId: number,
      inboxId: string,
      threadId: string
    ): Promise<void> {
      const res = await $api.post("/undoSend.php", {
        id: draftId,
        mailboxId: inboxId,
        threadId: threadId,
      });

      const { status, message } = res.data;

      if (status === "error") {
        throw new Error(message);
      }
    },
    async resendEmail(id: number): Promise<void> {
      const res = await $api.post("/resendMail.php", {
        id,
      });

      const { status, message } = res.data;

      if (status === "error") {
        throw new Error(message);
      }
    },
    async cancelScheduledEmail(
      emailId: number,
      inboxId: string,
      threadId: string
    ): Promise<void> {
      const res = await $api.post("/cancelScheduled.php", {
        emailId: emailId,
        mailboxId: inboxId,
        threadId: threadId,
      });

      const { status, message } = res.data;

      if (status === "error") {
        throw new Error(message);
      }
    },
    async deleteEmail(
      emailId: number,
      inboxId: string,
      threadId: string
    ): Promise<void> {
      const threadStore = useThreadStore();
      const { threadsDetailMap } = storeToRefs(threadStore);
      const threadItems = threadsDetailMap.value[threadId]?.threadItems;

      const res = await $api.post("/delete-email.php", {
        emailID: emailId,
        mailboxID: inboxId,
        threadID: threadId,
      });

      const { status, message } = res.data;

      if (status === "error") {
        throw new Error(message);
      }

      // Removing particular email from the store.
      if (threadItems) {
        threadsDetailMap.value[threadId]!.threadItems = threadItems.filter(
          (i) => {
            if (i.type === "email") {
              const emailData = i.data;
              if (emailData.id === emailId) return false;
              return i;
            }

            return i;
          }
        );
      }
    },
    async copyAttachment({
      files,
      inboxId,
    }: {
      files: number[];
      inboxId: string;
    }): Promise<ReplierAttachment[]> {
      const res = await $api.post("/copy-attachment", {
        files: files,
        mailboxID: inboxId,
      });

      const { status, data, message } = res.data;

      if (status === "error") {
        throw new Error(message);
      }
      return data.files.map((file: any) => file);
    },
  },
});
