enum InboxLabelEnum {
  assigned = 0,
  sent = 1,
  drafts = 2,
  mine = 4,
  trash = 5,
  scheduled = 6,
  closed = 7,
  spam = 8,
  snoozed = 9,
  unassigned = 10,
  starred = 11,
  mentions = 13,
  all = 14,
  discussions = 15,
  awaitingReply = 17,
  social = 18,
  updates = 19,
  promotions = 20,
  forums = 21,
  inbox = 12,
  advancedSearch = 14,
}

export default InboxLabelEnum;
