<template>
  <nav class="navbar navbar-header navbar-header-fixed">
    <div
      v-if="
        inbox?.type === 'mail' &&
        universalSettings?.forwarding &&
        universalSettings?.outgoing.outgoingUnverified &&
        justcallEnv
      "
      class="alert alert-warning tx-black ht-40 d-flex mg-b-0"
      style="
        width: 100%;
        justify-content: center;
        align-items: center;
        background-color: #fffaeb;
      "
    >
      <mdicon
        class="mg-r-5"
        name="alert"
        size="18"
        style="color: #f79009"
      ></mdicon
      ><span class="tx-bold mg-r-5">Action Needed!</span>Please configure your
      outbound settings to improve email deliverability and prevent spam
      flagging.
      <a
        :href="settingsLink"
        target="_blank"
        class="mg-l-5 text-decoration-underline"
        style="cursor: pointer"
        >Setup now</a
      >
    </div>
    <a id="mainMenuOpen" href="" class="burger-menu d-none d-md-flex d-lg-none">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-menu"
      >
        <line x1="3" y1="12" x2="21" y2="12"></line>
        <line x1="3" y1="6" x2="21" y2="6"></line>
        <line x1="3" y1="18" x2="21" y2="18"></line>
      </svg>
    </a>
    <a id="mailSidebar" href="" class="burger-menu d-md-none">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-arrow-left"
      >
        <line x1="19" y1="12" x2="5" y2="12"></line>
        <polyline points="12 19 5 12 12 5"></polyline>
      </svg>
    </a>
    <div class="d-flex" style="width: 100%">
      <!-- navbar-brand -->
      <div class="navbar-brand">
        <a :href="getAbsoluteUrl('')">
          <span> <img style="width: 150px" :src="logoUrl" />&nbsp; </span>
        </a>
      </div>

      <div id="navbarMenu" class="navbar-menu-wrapper pd-0">
        <div class="navbar-menu-header">
          <a id="mainMenuClose" href="">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-x"
            >
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </a>
        </div>
        <!-- navbar-menu-header -->
        <ul class="nav navbar-menu" style="max-width: 100% !important">
          <li
            v-for="(item, menuKey) in menu"
            :key="menuKey"
            class="nav-item"
            :class="item.children?.length && 'with-sub'"
            :style="`display: ${item.visible ? '' : 'none'}`"
          >
            <template v-if="item.children?.length && item.visible">
              <HWMenu
                placement="bottom-start"
                arrow
                :content-styles="`min-width: ${
                  item.width ? item.width : 'initial'
                }`"
              >
                <template #trigger>
                  <a
                    :href="item.link ? item.link : 'javascript:void(0)'"
                    class="nav-link"
                    @click.prevent
                  >
                    {{ item.title }}
                  </a>
                </template>

                <template #content="{ closeMenu }">
                  <div
                    style="
                      max-height: 460px;
                      overflow-y: auto;
                      overflow-x: hidden;
                    "
                  >
                    <template v-for="(child, j) in item.children">
                      <template v-if="child.children?.length && child.visible">
                        <HWMenu
                          :key="j"
                          content-styles="min-width: 200px"
                          placement="right-start"
                        >
                          <template #trigger>
                            <a
                              href="javascript:void(0)"
                              style="cursor: pointer; padding: 5px; width: 100%"
                              class="d-flex dropdown-item align-items-center justify-content-between"
                              @click.prevent
                            >
                              <div class="d-flex mg-2" style="width: 100%">
                                <div class="mr-2" style="color: #566476">
                                  <mdicon :name="child.icon" size="18" />
                                </div>

                                <span
                                  style="line-height: 1px; align-self: center"
                                >
                                  {{ child.title }}
                                </span>
                                <div class="ml-auto" style="color: #566476">
                                  <mdicon name="chevron-right" size="18" />
                                </div>
                              </div>
                            </a>
                          </template>

                          <template
                            #content="{ closeMenu: nestedChildCloseMenu }"
                          >
                            <div
                              style="
                                max-height: 460px;
                                overflow-y: auto;
                                overflow-x: hidden;
                              "
                            >
                              <a
                                v-for="(nestedChild, k) in child.children"
                                :key="k"
                                :href="nestedChild.link"
                                style="cursor: pointer; padding: 12px 5px"
                                class="d-flex dropdown-item align-items-center justify-content-between w-100"
                                @click="nestedChildCloseMenu"
                              >
                                <div>
                                  <div class="d-flex mg-2">
                                    <span
                                      style="
                                        line-height: 1px;
                                        align-self: center;
                                      "
                                    >
                                      {{ nestedChild.title }}
                                    </span>
                                  </div>
                                </div>
                              </a>
                              <div
                                v-if="child.id === 'helpcenter'"
                                class="nav-sub-item hover mt-1"
                                style="padding-left: 3px"
                              >
                                <div
                                  class="dropdown-divider"
                                  style="margin: 0; margin-bottom: 5px"
                                ></div>
                                <a
                                  class="nav-sub-link.hover"
                                  :href="getAbsoluteUrl('/helpcenter/create')"
                                  style="font-size: 14px; color: inherit"
                                >
                                  + Add Helpcenter
                                </a>
                              </div>
                            </div>
                          </template>
                        </HWMenu>
                      </template>

                      <template v-else>
                        <template v-if="child.isSpa">
                          <RouterLink
                            :key="j"
                            :to="child.link"
                            style="cursor: pointer; padding: 5px"
                            class="d-flex dropdown-item align-items-center justify-content-between w-100"
                            @click="closeMenu"
                          >
                            <div>
                              <div class="d-flex mg-2">
                                <div class="mr-2" style="color: #566476">
                                  <mdicon :name="child.icon" size="18" />
                                </div>

                                <template v-if="child.subTitle">
                                  <div class="d-flex flex-column">
                                    <strong>{{ child.title }}</strong>

                                    <span v-if="child.subTitle">
                                      {{ child.subTitle }}
                                    </span>
                                  </div>
                                </template>

                                <template v-else>
                                  <span
                                    style="line-height: 1px; align-self: center"
                                    >{{ child.title }}</span
                                  >
                                </template>
                              </div>
                            </div>
                          </RouterLink>
                        </template>

                        <template v-else>
                          <a
                            v-if="child.visible"
                            :key="j"
                            :href="child.link"
                            style="cursor: pointer; padding: 5px"
                            class="d-flex dropdown-item align-items-center justify-content-between w-100"
                            @click="closeMenu"
                          >
                            <div>
                              <div class="d-flex mg-2">
                                <div class="mr-2" style="color: #566476">
                                  <mdicon :name="child.icon" size="18" />
                                </div>

                                <template v-if="child.subTitle">
                                  <div class="d-flex flex-column">
                                    <strong>{{ child.title }}</strong>

                                    <span v-if="child.subTitle">
                                      {{ child.subTitle }}
                                    </span>
                                  </div>
                                </template>

                                <template v-else>
                                  <span
                                    style="line-height: 1px; align-self: center"
                                    >{{ child.title }}</span
                                  >
                                </template>
                              </div>
                            </div>
                          </a>
                        </template>
                      </template>
                    </template>
                  </div>
                  <div
                    v-if="menuKey === 'inboxes'"
                    class="nav-sub-item hover"
                    style="padding-left: 3px"
                  >
                    <div
                      class="dropdown-divider"
                      style="margin: 0; margin-bottom: 5px"
                    ></div>
                    <a
                      class="nav-sub-link.hover"
                      :href="getAbsoluteUrl('/create-inbox')"
                      style="font-size: 14px; color: inherit"
                    >
                      + Add Inbox
                    </a>
                  </div>
                </template>
              </HWMenu>
            </template>

            <template v-else-if="item.link && item.visible">
              <!-- TODO: need to change into a -->
              <a
                v-if="menuKey !== 'universal'"
                :href="item.link"
                class="nav-link"
              >
                {{ item.title }}
              </a>
              <RouterLink v-else :to="item.link" class="nav-link">
                {{ item.title }}
              </RouterLink>
            </template>
          </li>
        </ul>
      </div>
      <!-- navbar-menu-wrapper -->
      <div class="navbar-right">
        <button
          v-if="user?.base_plan == 0"
          class="btn btn-outline-primary btn-sm rounded bookdemoeasycalendar mg-r-10"
        >
          Request Demo
        </button>
        <a
          v-if="user?.base_plan == 0 && user?.role == 'Account Owner'"
          class="btn btn-warning btn-sm rounded"
          :href="upgradeUrl"
          style="font-weight: 700"
        >
          {{ upgradeMsg }}
        </a>
        <NavBarHelp style="margin-left: 24px; margin-right: 10px" />
        <NavBarApps v-if="!justcallEnv" style="margin-right: 10px" />
        <button
          v-if="!justcallEnv"
          class="btn-icon"
          style="margin-right: 10px"
          @click="openProductUpdatesDrawer"
        >
          <mdicon name="gift-outline" size="22"></mdicon>
        </button>
        <NavBarNotif />
        <NavBarAcc style="margin-left: 20px" />
      </div>
    </div>
  </nav>
</template>

<script lang="ts" setup>
import { useInboxStore } from "@/stores/inbox";
import { storeToRefs } from "pinia";
import { onMounted, ref, computed, defineAsyncComponent } from "vue";

import NavBarNotif from "./NavBarNotif.vue";
import NavBarHelp from "./NavBarHelp.vue";
import NavBarAcc from "./NavBarAcc.vue";
import type { InboxType } from "@/stores/types/inbox";
import { useRoute } from "vue-router";
import { useRootStore } from "@/stores/root";
import { useUserStore } from "@/stores/user";
import NavBarApps from "./NavBarApps.vue";
import { getAbsoluteUrl, getAppBaseUrl } from "@/utils/helperFunction";

const route = useRoute();
const rootStore = useRootStore();
const inboxStore = useInboxStore();
const userStore = useUserStore();

const { inboxes, inbox, inboxAccessMap, helpcenters, inboxesMap } =
  storeToRefs(inboxStore);
const { user, userSettings } = storeToRefs(userStore);

const inboxId = computed(() => {
  return route.params.id as string;
});
const inboxData = computed(() => inboxesMap.value[inboxId.value]);
const universalSettings = computed(() => inboxData.value?.universalSettings);
const justcallEnv = import.meta.env.VITE_JUSTCALL_ENV;

const upgradeMsg = computed(() => {
  if (justcallEnv) {
    return "Buy: Email Add-on";
  }
  return "Upgrade Account!";
});

const upgradeUrl = computed(() => {
  if (justcallEnv) {
    return (
      import.meta.env.VITE_APP_BASE_URL + "/upgrade?defaultShowEmailModal=1"
    );
  }
  return getAbsoluteUrl("/upgrade");
});

const teamsPlan: boolean =
  user.value &&
  (user.value?.planType > 2 ||
    user.value?.base_plan == 0 ||
    user.value?.base_plan == 1) &&
  user.value?.role !== "User"
    ? true
    : false;

const heightNavbarBanner992 = computed(() =>
  universalSettings.value?.forwarding &&
  universalSettings.value?.outgoing.outgoingUnverified &&
  justcallEnv
    ? "100px"
    : "60px"
);

const defaultView = userSettings.value?.defaultView
  ? userSettings.value.defaultView
  : "all";

const settingsLink = computed(() => {
  const baseUrl = getAppBaseUrl();
  return baseUrl + `/settings/inboxes/${inbox.value?.id}`;
});

const logoUrl =
  justcallEnv == "1"
    ? "https://cdn.justcall.io/assets-marketing/images/svg/logo.svg"
    : "https://cdn.helpwise.io/landingv3/svg/hwlogobyjc.webp";
const menu = ref<{
  [menuKey: string]: {
    title: string;
    link?: string;
    visible?: boolean;
    width?: string;
    children?: {
      id?: string;
      title: string;
      link: string;
      icon: string;
      subTitle?: string;
      isSpa?: boolean;
      visible?: boolean;
      children?: {
        title: string;
        link: string;
      }[];
    }[];
  };
}>({
  inboxes: {
    title: "Inboxes",
    width: "300px",
    visible: true,
  },
  universal: {
    title: "Universal Inbox",
    link: `/universal/me/${defaultView}?filter=open`,
    visible: true,
  },
  interact: {
    title: "Chat",
    children: [],
    visible: justcallEnv == "1" ? false : true,
  },
  manager: {
    title: "Manage",
    width: "200px",
    visible: true,
    children: [
      {
        title: "Users",
        icon: "account-outline",
        link: getAbsoluteUrl("/settings/users"),
        visible:
          user.value?.role !== "User"
            ? true
            : userSettings.value?.permissions.manageUsers,
      },
      {
        title: "Inboxes",
        icon: "inbox",
        link: getAbsoluteUrl("/settings/inboxes"),
        visible: true,
      },
      {
        title: "Signatures",
        icon: "fountain-pen-tip",
        link: getAbsoluteUrl("/settings/signatures"),
        visible: true,
      },
      {
        title: "Tags",
        icon: "tag-outline",
        link: getAbsoluteUrl("/settings/tags"),
        visible:
          user.value?.role !== "User"
            ? true
            : userSettings.value?.permissions.manageTags,
      },
      {
        title: "Teams",
        icon: "account-multiple-plus-outline",
        link: `/settings/teams`,
        visible: teamsPlan,
      },
      {
        title: "Import",
        icon: "upload",
        link: getAbsoluteUrl("/settings/import"),
        visible: true,
      },
      {
        title: "CSAT",
        icon: "chart-box-outline",
        link: getAbsoluteUrl("/settings/csat"),
        visible:
          justcallEnv == "1"
            ? false
            : user.value?.role !== "User"
            ? true
            : userSettings.value?.permissions.csat,
      },
      {
        title: "Contacts",
        icon: "account-box",
        link: getAbsoluteUrl("/contacts"),
        visible:
          user.value?.role !== "User"
            ? true
            : userSettings.value?.permissions.manageContacts,
      },
      {
        title: "Security",
        icon: "shield-account",
        link: getAbsoluteUrl("/settings/security"),
        visible:
          justcallEnv == "1"
            ? false
            : user.value?.role !== "User"
            ? true
            : false,
      },
      {
        title: "File Manager",
        icon: "folder",
        link: getAbsoluteUrl("/files"),
        visible: justcallEnv == "1" ? false : true,
      },
      {
        id: "helpcenter",
        title: "Help Centers",
        icon: "book",
        link: getAbsoluteUrl("/helpcenter/create"),
        children: [],
        visible:
          justcallEnv == "1"
            ? false
            : user.value?.role !== "User"
            ? true
            : userSettings.value?.permissions.manageHelpcenter,
      },
    ],
  },
  automation: {
    title: "Automation",
    width: "200px",
    visible: true,
    children: [
      {
        title: "Saved Replies",
        icon: "send",
        link: getAbsoluteUrl("/settings/replies"),
        visible: true,
      },
      {
        title: "Rules",
        icon: "inbox",
        link: getAbsoluteUrl("/settings/rules"),
        visible: user.value?.role !== "User" ? true : false,
      },
      {
        title: "SLA",
        icon: "clock-outline",
        link: getAbsoluteUrl("/settings/sla"),
        visible: user.value?.role !== "User" ? true : false,
      },
      {
        title: "Views",
        icon: "magnify",
        link: getAbsoluteUrl("/settings/views"),
        visible: user.value?.role !== "User" ? true : false,
      },
      {
        title: "Forms",
        icon: "upload",
        link: getAbsoluteUrl("/settings/forms"),
        visible: justcallEnv == "1" ? false : true,
      },
      {
        title: "Integrations",
        icon: "chart-box-outline",
        link: getAbsoluteUrl("/settings/integrations"),
        visible: true,
      },
      {
        title: "API",
        icon: "key-outline",
        link: getAbsoluteUrl("/settings/api"),
        visible: justcallEnv == "1" ? false : true,
      },
    ],
  },
  reports: {
    title: "Reports",
    link: getAbsoluteUrl("/reports"),
    visible: userSettings.value?.permissions.exportReport,
  },
});

const inboxIcon = ref<{
  [key in InboxType]: string;
}>({
  sms: "chat-outline",
  mail: "email-outline",
  "fb-feed": "facebook",
  chat: "message-outline",
  custom: "folder-cog-outline",
  facebook: "facebook",
  instagram: "instagram",
  "instagram-dm": "instagram",
  twitter: "twitter",
  twitterdm: "twitter",
  universal: "",
  whatsapp: "whatsapp",
});

const newExperience = ref(true);

const threadId = computed(() => route.params.threadId as string | undefined);

onMounted(async () => {
  const inboxesChildren: typeof menu.value.inboxes.children = [];
  const interactChildren: typeof menu.value.inboxes.children = [];

  inboxes.value.forEach((i) => {
    const isSpa =
      i.type.includes("instagram") ||
      i.type.includes("fb-feed") ||
      i.type.includes("facebook") ||
      (i.type.includes("chat") && inboxAccessMap.value.chat) ||
      (i.type.includes("sms") && inboxAccessMap.value.sms) ||
      (i.type.includes("whatsapp") && inboxAccessMap.value.whatsapp) ||
      i.type.includes("mail") ||
      i.type.includes("custom");

    const data = {
      icon: inboxIcon.value[i.type],
      link: isSpa
        ? i.isPersonal
          ? `/inboxes/${i.id}/inbox`
          : `/inboxes/${i.id}/${defaultView}`
        : getAbsoluteUrl(`/${i.type}/${i.id}/${defaultView}`), // TODO: need to update before deployment
      title: i.displayName,
      subTitle: i.externalAddress,
      isSpa,
    };

    inboxesChildren.push(data);

    if (i.type === "chat") {
      interactChildren.push({
        icon: inboxIcon.value[i.type],
        link: getAbsoluteUrl(`/widget/${i.id}`),
        title: i.displayName,
        subTitle: i.externalAddress,
        isSpa: false,
        visible: true,
      });
    }
  });

  // Populating inboxes
  menu.value.inboxes = {
    ...menu.value.inboxes,
    children: inboxesChildren,
  };

  // Populate interact with chat inboxes

  if (!interactChildren.length) {
    // remove interact if no chat inbox present
    delete menu.value.interact;
  } else {
    menu.value.interact.children = interactChildren;
  }

  // Populate helpcenters
  await inboxStore.fetchHelpcenters();

  const foundHelpcenters = menu.value.manager.children?.find(
    (c) => c.id === "helpcenter"
  );

  if (foundHelpcenters) {
    foundHelpcenters.children = [];

    if (helpcenters.value.length) {
      helpcenters.value.forEach((h) => {
        foundHelpcenters.children?.push({
          link: h.url,
          title: h.name,
        });
      });
    } else {
      delete foundHelpcenters.children;
      foundHelpcenters.title = "Create Help Center";
    }
  }
});

const handleSwitch = async (e: any) => {
  if (!inbox.value) return;

  try {
    newExperience.value = e.target.checked;

    const inboxId = inbox.value.id.toString();
    const inboxType = inbox.value.type;

    await inboxStore.toggleNewExperience(inboxId);

    const baseUrl = import.meta.env.VITE_BASE_URL;
    const url =
      baseUrl +
      `${inboxType}/${inboxId}/all` +
      (threadId.value ? `/${threadId.value}` : "");

    // redirect to old experience.
    window.location.replace(url);
  } catch (err) {
    newExperience.value = !newExperience.value;

    throw err;
  }
};

const openProductUpdatesDrawer = () => {
  const component = defineAsyncComponent(
    () => import("./NavBarProductUpdatesDrawer.vue")
  );

  rootStore.setDrawer<InstanceType<typeof component>>({
    component,
    props: {},
  });
};
</script>

<style scoped>
.nav-sub-item.hover:hover {
  color: #0168fa;
}

@media (min-width: 992px) {
  .navbar-header {
    height: v-bind(heightNavbarBanner992);
  }
}

@media (min-width: 1200px) {
  .navbar-header .navbar-right {
    min-width: 240px;
    width: auto !important;
  }
}
</style>
